/* CSS File for Inventory Component */

.animate-fade-in {
    animation: fadeIn 1s ease-in-out forwards;
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

.bg-primary {
    background-color: #007bff;
    /* Use the primary color defined in COLORS */
}

.bg-primary:hover {
    background-color: #0056b3;
    /* Darker shade on hover */
}

.toggle-checkbox:checked {
    @apply: right-0 border-green-400;
    right: 0;
    border-color: #68D391;
}

.toggle-checkbox:checked+.toggle-label {
    @apply: bg-green-400;
    background-color: #68D391;
}