/* AddProduct.css */


/* Shared styles */

.edit-product-container {
    width: 100%;
}

.edit-product-heading {
    font-weight: bold;
    font-size: 20px;
}

.edit-product-card {
    background: rgba(255, 255, 255, 0.2);
    /* Adjust the opacity value as needed */
    backdrop-filter: blur(10px);
    /* This creates the frosted glass effect, adjust blur value as needed */
    border-radius: 8px;
    /* Add a border radius to round the corners (optional) */
    padding: 20px;
    /* Add padding to create space for content inside the div (optional) */
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}

.edit-product-image-upload {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    flex-direction: column;
    border: 1px dashed #ccc;
}

.edit-product-image-upload-icon {
    font-size: 60px;
    cursor: pointer;
}

.edit-product-Variant-Box:hover {
    transform: scale(1.1);
    transition: all 0.3s ease-in-out;
    cursor: pointer;
    filter: blur(1px);
}

.edit-product-image-upload-msg {
    font-weight: bold;
    text-align: center;
    margin-top: 4rem;
    font-size: 14px;
    margin: 10px;
}

.edit-product-image-list {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}

.edit-product-image-item {
    width: 20%;
    margin: 10px;
    background: rgba(255, 255, 255, 0.2);
    /* Adjust the opacity value as needed */
    backdrop-filter: blur(10px);
    /* This creates the frosted glass effect, adjust blur value as needed */
    border-radius: 8px;
    /* Add a border radius to round the corners (optional) */
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}

.edit-product-image:hover {
    transform: scale(1.1);
    transition: all 0.3s ease-in-out;
    cursor: pointer;
    filter: blur(1px);
}

.edit-product-image {
    width: 100%;
    height: 100%;
    object-fit: contain;
    border: 1px solid #ccc;
    border-radius: 5px;
}

.edit-product-info-input {
    margin-bottom: 1rem;
}


/* Variant styles */

.edit-product-variant {
    display: flex;
    justify-content: space-between;
    margin-bottom: 1rem;
}


/* Buttons */

.edit-product-add-variant-btn {
    cursor: pointer;
    /* background-color: ${COLORS.primary}; */
    color: black;
    padding: 5px;
    border: none;
    border-radius: 4px;
}

.edit-product-submit-btn {
    cursor: pointer;
    /* background-color: ${COLORS.primary}; */
    color: white;
    padding: 0.5rem 1rem;
    border: none;
    border-radius: 4px;
}

.shipping-status-select {
    width: 100%;
}

.order-status {
    padding: 2px;
    color: white;
    display: flex;
    justify-content: center;
    border-radius: 20px;
    align-items: center;
    font-weight: 400;
}


/* Quill Editor */

.edit-product-description {
    height: 300px;
    margin-bottom: 1rem;
}

.order-button-layout {
    display: flex;
    width: 100%;
    gap:10px
}

.order-button {
    width: 100%;
    justify-content: center;
    text-align: center;
    font-weight: bold;
    padding: 5px 10px;
    border-radius: 5px;
    margin-top: 10px;
    cursor: pointer;
}



/* Additional Styles */


/* Add any additional styles specific to this page as needed */