.sidebar-label {
    display: flex;
    align-items: center;
    width: 100%;
    padding: 10px;
    font-weight: bold;
    font-size: 12px;
    border-radius: 10px;
    cursor: pointer;
}

.sidebar-label:hover {
    background-color: #ffc107;
    transition: background-color 0.3s ease;
}

.sidebar-label.open {
    justify-content: flex-start;
}

.icon-container {
    padding-right: 10px;
}

.icon {
    display: flex;
}

.label {
    padding-left: 10px;
}