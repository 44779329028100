.Animation-Container {
    width: 500px;
    margin-right: auto;
}

.g_id_signin {
    position: absolute;
    opacity: 0;
}

.main-google-signin-button {
    position: absolute;
    overflow: hidden;
    opacity: 0;
}

.Main-Login-Container {
    height: 100vh;
    display: flex;
    justify-content: center;
}

.Login-Method-Container {
    justify-content: center;
    margin: auto;
    display: block;
    width: 30%;
}

@media screen and (max-width: 768px) {
    .Animation-Container {
        display: none;
    }
    .Login-Method-Container {
        justify-content: center;
        margin: auto;
        display: block;
        width: 80%;
        align-items: center;
    }
}