.sidebar {
    width: 80px;
    transition: width 0.3s ease;
    padding-top: 20px;
    border-right: 1px solid #e5e5e5;
    margin-right: 1rem;
    height: 100vh;
    top: 0;
    left: 0;
    overflow-y: hidden;
    background-color: white;
    position: fixed;
}

.sidebar-open {
    width: 200px;
    transition: width 0.3s ease;
}

.fakeDiv {
    width: 80px;
    transition: width 0.3s ease;
    padding-top: 20px;
    border-right: 1px solid #e5e5e5;
    margin-right: 1rem;
    height: 100vh;
    top: 0;
    left: 0;
    overflow-y: hidden;
    background-color: white;
}

.fakeDiv-open {
    width: 200px;
    transition: width 0.3s ease;
}

.logo-container {
    display: flex;
    cursor: pointer;
}

.navbar-brand {
    display: flex;
}

.logo-img {
    width: 34px;
    height: 34px;
}

.logo-text {
    font-weight: bold;
    padding-top: 2px;
}

.sidebar-content {
    display: flex;
    flex-direction: column;
    margin-top: 20px;
    height: calc(70vh - 20px);
    /* Subtract the top padding */
    justify-content: center;
}

.divider {
    width: 100%;
    height: 1px;
    background-color: #000;
    margin-top: 20px;
    margin-bottom: 20px;
}


/* hide sidebar if width less than 650 px */

@media (max-width: 650px) {
    .sidebar {
        display: none;
    }
    .fakeDiv {
        display: none;
    }
}