.add-category-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    /* Adjust based on your layout */
}

.add-category-content {
    width: 85vw;
    /* Adjust width as needed */
    display: flex;
    flex-direction: column;
    align-items: center;
}