/* inventory.css */

.order-container {
    width: 100vw;
}

.order-container :hover {
    transition: 0.2s;
    transform: scale(1.01);
}

.order-product-container {
    width: 82vw;
    margin-left: 17px;
}

.order-product {
    display: flex;
    justify-content: space-between;
    padding: 10px;
    border: 1px solid #e0e0e0;
    cursor: pointer;
    border-radius: 5px;
    box-shadow: 0px 0px 8px 0px #e0e0e0;
    margin-bottom: 20px;
    background: "rgba(255, 255, 255, 0.2)";
    backdrop-filter: "blur(10px)";
    /* This creates the frosted glass effect, adjust blur value as needed */
    border-radius: "8px";
    /* Add a border radius to round the corners (optional) */
    padding: "20px";
    /* Add padding to create space for content inside the div (optional) */
    box-shadow: "0 0 10px rgba(0, 0, 0, 0.2)";
}

.order-details {
    display: flex;
    gap: 30px;
}

.order-product img {
    max-width: 100%;
    max-height: 100%;
    border-radius: 5px;
    height: 15vh;
    width: 6vw;
}

.order-product-info {
    font-size: 14px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.order-product-info p {
    font-size: 15px;
    margin-bottom: 2px;
}



.order-action-btn {
    padding: 5px 20px;
    border: 1px solid #e0e0e0;
    border-radius: 5px;
    font-size: 12px;
    text-align: center;
    margin-bottom: 10px;
}

.order-action-btn:hover {
    font-weight: bold;
    border: 1px solid #ffc107;
    color: #000;
    background-color: #ffc107;
}


/* Additional styles for the overall layout */


/* Styles for the filter panel (assuming it's included in the component) */

.inventory-filter-panel {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
}

.inventory-filter-panel label {
    margin-right: 10px;
}

.order-product-actions {
    display: flex;
    gap: 10px;
    flex-direction: column;
    justify-content: center;
}

.inventory-filter-panel input[type="text"] {
    padding: 5px 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
}

.inventory-filter-panel button {
    padding: 5px 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    background-color: #fff;
    color: #fff;
    cursor: pointer;
}


/* Styles for the add category modal (assuming it's included in the component) */

.inventory-add-category-modal {
    /* Your styles for the modal */
}